import PropTypes from 'prop-types'
import { useMemo, useState } from 'react'
import PaginateContext from './PaginateContext'

const PaginateProvider = ({ children }) => {
    const [ buttonsRenderedFrom, setButtonsRenderedFrom ] = useState(1)
    const [ pageRangeDisplayed, setPageRangeDisplayed ] = useState(0)
    const value = useMemo(() => (
        {
            buttonsRenderedFrom,
            setButtonsRenderedFrom,
            pageRangeDisplayed,
            setPageRangeDisplayed
        }
    ), [ buttonsRenderedFrom, setButtonsRenderedFrom, pageRangeDisplayed, setPageRangeDisplayed ])
    return (
        <PaginateContext.Provider value={value}>
            {children}
        </PaginateContext.Provider>
    )
}

PaginateProvider.propTypes = {
    children: PropTypes.node.isRequired
}

export default PaginateProvider
