import PropTypes from 'prop-types'
import { useMemo, useState } from 'react'
import InputContext from './InputContext'

const InputProvider = ({ children }) => {
    const [ focusOff, setFocusOff ] = useState(false)
    const value = useMemo(() => ({
        focusOff, setFocusOff
    }), [ focusOff, setFocusOff ])
    return (
        <InputContext.Provider value={value}>
            {children}
        </InputContext.Provider>
    )
}

InputProvider.propTypes = {
    children: PropTypes.node.isRequired
}

export default InputProvider
