import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { checkInformedSales, checkUninformedSales } from '../../redux/sales'
import checkImage from '../../../assests/img/garrapata.png'

const CheckButton = ({ doesExist = true }) => {
    const dispatch = useDispatch()
    const sales = useSelector((state) => state.sales.sales)
    const salesDescripted = sales.filter((sale) => sale.descripcion)
    const salesUndescripted = sales.filter((sale) => !sale.descripcion)
    const getTooltipText = () => {
        switch (doesExist) {
            case true:
                if (salesDescripted.every((sale) => sale.isChecked)) {
                    return 'Deseleccione los productos propios'
                }
                return 'Seleccione los productos propios'
            default:
                if (salesUndescripted.every((sale) => sale.isChecked)) {
                    return 'Deseleccione los productos de terceros'
                }
                return 'Seleccione los productos de terceros'
        }
    }
    return (
        <span className="ml-2 text-gray-800 hidden md:flex items-center">
            <button
                type="button"
                // data-tooltip={`${getTooltipText()}`}
                className="ml-2 text-gray-200" // custom-tooltip bottom-tooltip"
                onClick={() => {
                    if (doesExist) dispatch(checkInformedSales())
                    else dispatch(checkUninformedSales())
                }}
            >
                <img src={checkImage} alt="Check" className="w-4 hidden lg:block mr-1" />
            </button>
            {getTooltipText()}
        </span>
    )
}

CheckButton.propTypes = {
    doesExist: PropTypes.bool
}

export default CheckButton
