/* eslint-disable no-unused-vars */
import { useSelector, useDispatch } from 'react-redux'
import { useRef, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { setChecked } from '../../redux/sales'
import trashImage from '../../../assests/img/1200px-OOjs_UI_icon_trash.png'
import DeleteModal from '../../common/DeleteModal'

const SaleRow = ({
    code,
    stock = true,
    description,
    quantity,
    unitPrice,
    date,
    time
}) => {
    const checkboxRef = useRef()
    const codeCell = useRef()
    const [ saleCode, setSaleCode ] = useState('')
    const [ openModal, setOpenModal ] = useState(false)
    const closeModal = () => setOpenModal(false)
    const dispatch = useDispatch()
    const sales = useSelector((state) => state.sales.sales)
    const actualSale = sales.find((sale) => sale.codigo === code)
    const checkSale = (e) => {
        e.stopPropagation()
        dispatch(setChecked(code))
    }
    const cleanSelected = (givenRow) => {
        const tableBody = givenRow.parentElement
        const totalRows = Array.from(tableBody.querySelectorAll('tr'))
        const givenRowIndex = totalRows.findIndex((row) => row === givenRow)
        for (let i = 0; i < totalRows.length; i++) {
            if (i !== givenRowIndex) {
                if (totalRows[i].classList.contains('selected')) {
                    totalRows[i].classList.remove('selected')
                    break
                }
            }
        }
    }
    const setSelected = (e) => {
        const tableRow = e.target.closest('tr')
        if (!(tableRow.classList.contains('selected'))) {
            cleanSelected(tableRow)
            tableRow.classList.add('selected')
        } else tableRow.classList.toggle('selected')
    }
    useEffect(() => {
        checkboxRef.current.checked = actualSale.isChecked
    }, [ actualSale ])
    return (
        <>
            {
                openModal
                    && (
                        <DeleteModal
                            selectedCode={saleCode}
                            closeModal={closeModal}
                        />
                    )
            }
            <tr
                className="even:bg-gray-100 odd:bg-gray-200 cursor-pointer sale-hover px-4 lg:px-0 sale-row"
                onClick={setSelected}
            >
                <td className="py-3 lg:py-2 hidden lg:table-cell">
                    <input
                        type="checkbox"
                        className={`${description ? 'blue-check' : 'green-check'}`}
                        onClick={checkSale}
                        ref={checkboxRef}
                    />
                </td>
                <td
                    data-label="Código"
                    className="py-3 px-3 lg:py-2 text-left text-lg responsive-tbody-cell"
                    ref={codeCell}
                >
                    {code}
                </td>
                <td data-label="Cantidad" className="py-3 lg:py-2 text-lg responsive-tbody-cell">{quantity || '-'}</td>
                <td data-label="Descripción" className="py-3 text-left lg:py-2 responsive-tbody-cell">
                    <span className="basis-2/3 md:basis-1/2 shrink-0">
                        {description || '-'}
                    </span>
                </td>
                <td data-label="Estado" className="py-3 lg:py-2 text-sm responsive-tbody-cell">
                    {
                        (description && unitPrice)
                            ? (
                                <div className={`flex items-center gap-2 ${stock ? 'text-green-400' : 'text-red-400'} w-fit lg:mx-auto`}>
                                    <div className={`w-2 h-2 ${stock ? 'bg-green-400' : 'bg-red-400'} rounded-full`} />
                                    <span>{stock ? 'En stock' : 'Falta de stock'}</span>
                                </div>
                            )
                            : '-'
                    }
                </td>
                <td data-label="Fecha y Hora" className="py-3 lg:py-2 text-xs responsive-tbody-cell">
                    <div>{date}</div>
                    <div>{time}</div>
                </td>
                <td data-label="Precio Unitario" className="py-3 px-3 text-right lg:py-2 text-lg responsive-tbody-cell">
                    {unitPrice ? `$${unitPrice.toFixed(2)}` : '-'}
                </td>
                <td data-label="Precio Total" className="py-3 px-3 text-right lg:py-2 text-lg responsive-tbody-cell">
                    {unitPrice ? `$${(quantity * unitPrice).toFixed(2)}` : '-'}
                </td>
                <td className="py-3 lg:py-2 hidden lg:table-cell">
                    <button
                        type="button"
                        className="w-[20px]"
                        onClick={(e) => {
                            e.stopPropagation()
                            setSaleCode(codeCell.current.innerText)
                            setOpenModal(true)
                        }}
                    >
                        <img src={trashImage} alt="Trash" />
                    </button>
                </td>
            </tr>
        </>
    )
}

SaleRow.propTypes = {
    code: PropTypes.string.isRequired,
    stock: PropTypes.bool,
    description: PropTypes.string,
    quantity: PropTypes.number,
    unitPrice: PropTypes.number,
    date: PropTypes.string.isRequired,
    time: PropTypes.string.isRequired
}

export default SaleRow
