/* eslint-disable no-unused-vars */
import PropTypes from 'prop-types'
import {
    Formik,
    Form,
    Field,
    ErrorMessage
} from 'formik'
import {
    useContext,
    useEffect,
    useState,
    useRef
} from 'react'
import { useDispatch } from 'react-redux'
import { removeSales } from '../../redux/sales'
import InputContext from '../../context/InputContext'
import fetchData from '../../../utils/fetchData'
import Loader from './Loader'
import AlertModal from '../../common/AlertModal'

const SalesForm = ({ type, givenSales, closeForm }) => {
    const salesList = useRef()
    const [ isSending, setIsSending ] = useState(false)
    const [ sendedData, setSendedData ] = useState(false)
    const [ errorData, setErrorData ] = useState(false)
    const [ items, setItems ] = useState([])
    const { setFocusOff } = useContext(InputContext)
    const dispatch = useDispatch()
    const deleteSales = () => {
        dispatch(removeSales(givenSales))
        closeForm()
    }
    const setNewQuantity = (e) => {
        const productRow = e.target.parentElement.parentElement
        const codeCell = productRow.firstElementChild
        const productObject = {
            id: codeCell.innerText,
            quantity: parseInt(e.target.value, 10)
        }
        setItems(items.map(
            (product) => (product.productId !== productObject.id
                ? product
                : {
                    ...product,
                    quantity: productObject.quantity
                }
            )
        ))
    }
    useEffect(() => {
        const salesReceived = givenSales.map((sale) => ({
            productId: sale.codigo,
            quantity: sale.quantity
        }))
        setItems(salesReceived)
    }, [])
    useEffect(() => {
        setFocusOff(true)
        return () => setFocusOff(false)
    }, [])
    return (
        <div className="auto-modal bg-transparent flex items-center justify-center font-poppins text-black">
            { isSending && <Loader /> }
            { sendedData && <AlertModal type="success" message="El pedido fue enviado exitosamente!" /> }
            { errorData && <AlertModal message="El pedido no pudo realizarse. Por favor, vuelva a intentarlo." /> }
            <div
                className="w-[80%] lg:h-[550px] bg-gray-100 py-6 lg:py-8 px-10 lg:px-12 text-sm grid lg:grid-cols-2 gap-6"
            >
                <div className="flex flex-col gap-4 lg:gap-8">
                    <div>
                        <h2 className="font-bold text-2xl mb-2">Enviar pedido</h2>
                        {
                            type === 'described'
                                ? <p>Ingrese un mensaje para el destinatario.</p>
                                : (
                                    <p>
                                        Ingrese un mensaje para el destinatario y un mail al que
                                        realizar el pedido.
                                    </p>
                                )
                        }
                    </div>
                    <Formik
                        initialValues={{ email: '', message: '' }}
                        onSubmit={async (values) => {
                            if (items.every(
                                (product) => !Number.isNaN(product.quantity) && product.quantity > 0
                            )) {
                                const dataSended = values.email !== ''
                                    ? {
                                        notes: values.message,
                                        email: values.email,
                                        items
                                    }
                                    : {
                                        notes: values.message,
                                        items
                                    }
                                setIsSending(true)
                                const isSended = await fetchData(dataSended)
                                if (isSended) {
                                    setIsSending(false)
                                    setSendedData(true)
                                    deleteSales()
                                    setTimeout(() => {
                                        setSendedData(false)
                                        closeForm()
                                    }, 2000)
                                } else {
                                    setIsSending(false)
                                    setErrorData(true)
                                    setTimeout(() => {
                                        setErrorData(false)
                                        closeForm()
                                    }, 2000)
                                }
                            }
                        }}
                        validate={(values) => {
                            const errors = {}
                            if (type !== 'described') {
                                if (!values.email) {
                                    errors.email = 'Por favor ingrese un correo.'
                                } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
                                    errors.email = 'El correo ingresado no es válido.'
                                }
                            }
                            if (!values.message) {
                                errors.message = 'Por favor ingrese un mensaje'
                            }
                            return errors
                        }}
                    >
                        {({
                            values, errors, handleChange, handleBlur
                        }) => (
                            <Form autoComplete="off" className="h-full flex flex-col">
                                {
                                    type === 'undescribed'
                                        && (
                                            <div className="mb-4">
                                                <Field
                                                    type="email"
                                                    placeholder="info@distrisuper.com"
                                                    id="enterprise-email"
                                                    name="email"
                                                    className="p-4 outline-none bg-[#212121] w-full text-white"
                                                />
                                                <ErrorMessage name="email">
                                                    {() => (
                                                        <p
                                                            className="text-xs text-red-500 font-medium mt-1"
                                                        >
                                                            {errors.email}
                                                        </p>
                                                    )}
                                                </ErrorMessage>
                                            </div>
                                        )
                                }
                                <div>
                                    <textarea
                                        placeholder="Tu mensaje"
                                        name="message"
                                        className="w-full p-4 resize-none bg-[#FAFAFA] h-40 lg:h-[15rem] outline-none text-black"
                                        value={values.message}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />
                                    <ErrorMessage name="message">
                                        {() => (
                                            <p className="text-xs text-red-500 font-medium mt-1">{errors.message}</p>
                                        )}
                                    </ErrorMessage>
                                </div>
                                <div className="flex gap-3 mt-4 lg:mt-auto">
                                    <input
                                        type="submit"
                                        value="Enviar"
                                        className="form-button"
                                    />
                                    <button
                                        type="button"
                                        className="form-button bg-red-500"
                                        onClick={() => closeForm()}
                                    >
                                        Cancelar
                                    </button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
                <div className="order-first">
                    <h2 className="font-bold text-2xl mb-2">Lista de productos</h2>
                    <div className="max-h-[150px] lg:max-h-[450px] overflow-auto products-list">
                        <table className="w-full">
                            <thead>
                                <tr className="text-left">
                                    <th className="px-3 py-1">Código</th>
                                    <th className="px-3 py-1">Marca</th>
                                    <th className="px-3 py-1">Descripción</th>
                                    <th className="px-3 py-1">Cantidad</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    givenSales.map(({
                                        codigo,
                                        descripcion,
                                        marca,
                                        quantity
                                    }, index) => (
                                        <tr key={`${codigo}${index}`} className="even:bg-gray-100 odd:bg-gray-200">
                                            <td className="p-3">{codigo}</td>
                                            <td className="p-3">{`${marca || '-'}`}</td>
                                            <td className="p-3">{`${descripcion || '-'}`}</td>
                                            <td className="p-3">
                                                <input
                                                    type="number"
                                                    className="w-full outline-none bg-gray-100 p-2 border border-solid border-gray-500 rounded-sm text-center"
                                                    defaultValue={quantity}
                                                    onChange={setNewQuantity}
                                                />
                                            </td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}

SalesForm.propTypes = {
    type: PropTypes.oneOf(['described', 'undescribed']).isRequired,
    givenSales: PropTypes.arrayOf(PropTypes.shape({
        codigo: PropTypes.string.isRequired,
        descripcion: PropTypes.string,
        precio: PropTypes.number,
        marca: PropTypes.string,
        isChecked: PropTypes.bool.isRequired,
        quantity: PropTypes.number.isRequired
    })).isRequired,
    closeForm: PropTypes.func.isRequired
}

export default SalesForm
