import { useState, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import SalesForm from './SalesForm'

const SendButton = ({ doesExist = true }) => {
    const buttonRef = useRef()
    const [ sendForm, setSendForm ] = useState(false)
    const [ disabledButton, setDisabledButton ] = useState(true)
    const sales = useSelector((state) => state.sales.sales)
    const salesDescripted = sales.filter((sale) => sale.isChecked && sale.descripcion)
    const salesUndescripted = sales.filter((sale) => sale.isChecked && !sale.descripcion)
    const postSales = async () => {
        if (!disabledButton) {
            setSendForm(true)
        }
    }
    const getButtonText = () => {
        switch (doesExist) {
            case true:
                if (salesDescripted.length > 0) return `Hacer pedido (${salesDescripted.length})`
                return 'Hacer pedido'
            default:
                if (salesUndescripted.length > 0) return `Hacer pedido (${salesUndescripted.length})`
                return 'Hacer pedido'
        }
    }
    useEffect(() => {
        let isChecked = 0
        for (let i = 0; i < sales.length; i++) {
            const condition = doesExist
                ? sales[i].isChecked && sales[i].descripcion
                : sales[i].isChecked && !sales[i].descripcion
            if (condition) {
                isChecked += 1
                break
            }
        }
        if (isChecked > 0) setDisabledButton(false)
        else setDisabledButton(true)
    }, [ sales ])
    return (
        <>
            {
                sendForm
                    && (
                        <SalesForm
                            type={doesExist ? 'described' : 'undescribed'}
                            givenSales={doesExist ? salesDescripted : salesUndescripted}
                            closeForm={() => setSendForm(false)}
                        />
                    )
            }
            <button
                type="button"
                data-tooltip={`${doesExist ? 'Enviar productos propios' : 'Enviar productos de terceros'}`}
                className={` 
                    send-button
                    bg-gradient-to-r
                    ${doesExist ? 'from-blue-500' : 'from-green-600'} 
                    ${doesExist ? 'to-blue-600' : 'to-green-700'}
                    custom-tooltip 
                    top-tooltip 
                    ${disabledButton && 'opacity-20 cursor-auto'}`}
                onClick={postSales}
                ref={buttonRef}
            >
                {`${getButtonText()}`}
            </button>
        </>
    )
}

SendButton.propTypes = {
    doesExist: PropTypes.bool
}

export default SendButton
