import PropTypes from 'prop-types'

const AlertModal = ({ type = 'error', message = 'Error! Ninguna fila fue seleccionada.' }) => (
    <div className="auto-modal">
        <div className={`alert ${type === 'error' ? 'alert-error' : 'alert-success'} w-[80%] md:w-[70%] mx-auto mt-3`}>
            <div>
                {
                    type === 'error'
                        ? (
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="stroke-current flex-shrink-0 h-6 w-6"
                                fill="none"
                                viewBox="0 0 24 24"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
                                />
                            </svg>
                        )
                        : (
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="stroke-current flex-shrink-0 h-6 w-6"
                                fill="none"
                                viewBox="0 0 24 24"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                                />
                            </svg>
                        )
                }
                <span>{message}</span>
            </div>
        </div>
    </div>
)

AlertModal.propTypes = {
    type: PropTypes.oneOf([ 'error', 'success' ]),
    message: PropTypes.string
}

export default AlertModal
