/* eslint-disable no-unused-vars */
import { useDispatch, useSelector } from 'react-redux'
import { useContext, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import {
    getSales,
    setEnteredText,
    setInitialStatus,
    addSale
} from '../../redux/sales'
import PaginateContext from '../../context/PaginateContext'
import FilterContext from '../../context/FilterContext'
import InputContext from '../../context/InputContext'

const SearchInput = ({ pageMaxRows }) => {
    const dispatch = useDispatch()
    const inputRef = useRef()
    const actualPage = useSelector((state) => state.sales.page)
    const status = useSelector((state) => state.sales.status)
    const sales = useSelector((state) => state.sales.sales)
    const {
        buttonsRenderedFrom,
        setButtonsRenderedFrom,
        pageRangeDisplayed
    } = useContext(PaginateContext)
    const { filterOff, setFilterOff } = useContext(FilterContext)
    const { focusOff } = useContext(InputContext)
    useEffect(() => {
        const changePagesRendered = () => new Promise((resolve) => {
            if (sales.length > actualPage * pageMaxRows) {
                if (actualPage - buttonsRenderedFrom >= pageRangeDisplayed) {
                    setButtonsRenderedFrom(buttonsRenderedFrom + pageRangeDisplayed)
                }
            }
            resolve()
        })
        const setStatus = async () => {
            await changePagesRendered()
            dispatch(setInitialStatus())
        }
        if (status === 'success') setStatus()
    }, [ status ])
    const focusOnBlur = (e) => {
        if (!focusOff) {
            e.target.focus({
                preventScroll: true
            })
        }
    }
    const filterOnEnter = (e) => {
        dispatch(setEnteredText(e.target.value))
    }
    const fetchOnEnter = (e) => {
        if (e.target.value !== '') {
            if (e.keyCode === 13) {
                dispatch(addSale(e.target.value.toUpperCase()))
                dispatch(getSales(e.target.value.toUpperCase()))
                e.target.value = ''
                filterOnEnter(e)
            }
        }
    }
    useEffect(() => {
        const setAsyncFilter = async () => {
            const clearInput = () => new Promise((resolve) => {
                if (inputRef.current.value !== '') inputRef.current.value = ''
                resolve()
            })
            await clearInput()
            setFilterOff(false)
        }
        if (filterOff) setAsyncFilter()
    }, [ filterOff ])
    useEffect(() => {
        if (!focusOff) inputRef.current.focus()
    }, [ focusOff ])
    return (
        <input
            type="text"
            ref={inputRef}
            className="outline-none ml-3 p-2 rounded text-xl font-lg text-center bg-gray-100 text-gray-700"
            onBlur={focusOnBlur}
            onKeyDown={fetchOnEnter}
            onChange={filterOnEnter}
        />
    )
}

SearchInput.propTypes = {
    pageMaxRows: PropTypes.number.isRequired
}

export default SearchInput
