import { configureStore } from '@reduxjs/toolkit'
import {
    persistReducer,
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER
} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import salesReducer from './sales'

const persistConfig = {
    key: 'root',
    version: 1,
    storage,
    blacklist: ['inputText', 'filterText', 'status']
}

const persistedReducer = persistReducer(persistConfig, salesReducer)
export default configureStore({
    reducer: {
        sales: persistedReducer
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: {
            ignoreActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
        }
    }),
    devTools: process.env.NODE_ENV !== 'production'
})
