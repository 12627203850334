const Loader = () => (
    <div className="auto-modal flex justify-center items-center">
        <div className="flex items-center gap-3">
            <div className="loader-circle" />
            <div className="loader-circle" />
            <div className="loader-circle" />
        </div>
    </div>
)

export default Loader
