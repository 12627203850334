export default async function sendData(givenData) {
    const fetchConfig = {
        method: 'POST',
        headers: { 'Content-type': 'application/json' },
        body: JSON.stringify(givenData)
    }
    try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/clip/order`, fetchConfig)
        if (response.status !== 201) return null
        const dataResponse = await response.json()
        return dataResponse
    } catch {
        return null
    }
}
