import PropTypes from 'prop-types'
import { useMemo, useState } from 'react'
import FilterContext from './FilterContext'

const FilterProvider = ({ children }) => {
    const [ filterOff, setFilterOff ] = useState(false)
    const value = useMemo(() => ({
        filterOff, setFilterOff
    }), [ filterOff, setFilterOff ])
    return (
        <FilterContext.Provider value={value}>
            {children}
        </FilterContext.Provider>
    )
}

FilterProvider.propTypes = {
    children: PropTypes.node.isRequired
}

export default FilterProvider
