import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { removeSale, removeAllSales } from '../redux/sales'

const DeleteModal = ({
    closeModal,
    selectedCode,
    deleteAll = false
    // cleanSelected,
    // setDelete
}) => {
    const dispatch = useDispatch()
    const deleteSelectedRow = async () => {
        if (!deleteAll) dispatch(removeSale(selectedCode))
        else dispatch(removeAllSales())
        closeModal()
        // cleanSelected()
        // setDelete()
    }
    return (
        <div className="auto-modal flex justify-center items-center">
            <div className="bg-gray-500 w-[90%] sm:w-[80%] md:w-[60%] lg:w-[40%] h-[150px] p-5 font-medium rounded-lg shadow-md flex flex-col">
                <p className="text-lg">
                    {
                        !deleteAll
                            ? `¿Está seguro que desea borrar la fila correspondiente al código ${selectedCode}?`
                            : '¿Está seguro que desea borrar todas las filas?'
                    }
                </p>
                <div className="btn-container mt-auto self-end flex gap-1">
                    <button type="button" className="btn btn-sm btn-primary" onClick={deleteSelectedRow}>Sí</button>
                    <button type="button" className="btn btn-sm btn-primary" onClick={closeModal}>No</button>
                </div>
            </div>
        </div>
    )
}

DeleteModal.propTypes = {
    closeModal: PropTypes.func.isRequired,
    // cleanSelected: PropTypes.func.isRequired,
    selectedCode: PropTypes.string,
    deleteAll: PropTypes.bool
    // setDelete: PropTypes.func.isRequired
}

export default DeleteModal
