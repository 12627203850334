/* eslint-disable consistent-return */
/* eslint-disable max-len */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { Buffer } from 'buffer'

export const getSales = createAsyncThunk('sales/getSales', async (givenId, thunkAPI) => {
    const auxGivenId = givenId.toUpperCase()
    const encodedId = Buffer.from(auxGivenId).toString('base64')
    const response = await fetch(`${process.env.REACT_APP_API_URL}/article/${encodedId}/data`)
    const dataResponse = await response.json()
    const doesCodeExist = dataResponse.data.find((product) => (product.codigo === auxGivenId || product.barCode === auxGivenId))
    if (dataResponse.data.length > 0 && doesCodeExist) return dataResponse.data
    return thunkAPI.rejectWithValue({
        rejectedCode: auxGivenId
    })
})

const initialState = {
    page: 1,
    sales: [],
    status: null,
    inputText: '',
    filterText: false
}

const salesSlice = createSlice({
    name: 'sales',
    initialState,
    reducers: {
        changePage: (state, action) => {
            if (state.page !== action.payload) state.page = action.payload
        },
        removeSale: (state, action) => {
            if (!(state.sales.find((sale) => sale.codigo === action.payload))) return state
            return {
                ...state,
                sales: state.sales.filter((sale) => sale.codigo !== action.payload)
            }
        },
        removeSales: (state, action) => ({
            ...state,
            sales: state.sales.filter((sale) => !(action.payload.find(
                (givenSale) => givenSale.codigo === sale.codigo
            )))
        }),
        removeAllSales: (state) => ({
            ...state,
            sales: initialState.sales
        }),
        addSale: (state, action) => {
            const saleDate = new Date()
            const dateMinutes = saleDate.getMinutes() <= 9 ? `0${saleDate.getMinutes()}` : saleDate.getMinutes()
            if (!(state.sales.find((sale) => sale.codigo === action.payload))) {
                return {
                    ...state,
                    sales: state.sales.concat({
                        codigo: action.payload,
                        isChecked: false,
                        date: saleDate.toLocaleDateString(),
                        time: `${saleDate.getHours()}:${dateMinutes}`
                    })
                }
            }
            return {
                ...state,
                sales: state.sales.map(
                    (sale) => (sale.codigo !== action.payload
                        ? sale
                        : {
                            ...sale,
                            date: saleDate.toLocaleDateString(),
                            time: `${saleDate.getHours()}:${dateMinutes}`
                        }
                    )
                )
            }
        },
        addQuantity: (state, action) => {
            if (Number.isNaN(action.payload.quantity)) return state
            return {
                ...state,
                sales: state.sales.map(
                    (sale) => (sale.codigo !== action.payload.code
                        ? sale
                        : { ...sale, quantity: sale.quantity + action.payload.quantity }
                    )
                )
            }
        },
        removeQuantity: (state, action) => {
            if (Number.isNaN(action.payload.quantity)) return state
            return {
                ...state,
                sales: state.sales.map(
                    (sale) => (sale.codigo !== action.payload.code
                        ? sale
                        : {
                            ...sale.quantity < action.payload.quantity
                                ? sale
                                : { ...sale, quantity: sale.quantity - action.payload.quantity }
                        }
                    )
                )
            }
        },
        setEnteredText: (state, action) => {
            state.inputText = action.payload
        },
        activeFilter: (state, action) => {
            state.filterText = action.payload
        },
        setInitialStatus: (state) => {
            state.status = null
        },
        setChecked: (state, action) => ({
            ...state,
            sales: state.sales.map(
                (sale) => (sale.codigo !== action.payload
                    ? sale
                    : { ...sale, isChecked: !sale.isChecked }
                )
            )
        }),
        checkInformedSales: (state) => {
            const salesFiltered = state.sales.filter((sale) => sale.descripcion)
            if (salesFiltered.every((sale) => sale.isChecked)) {
                return {
                    ...state,
                    sales: state.sales.map(
                        (sale) => (sale.descripcion
                            ? { ...sale, isChecked: false }
                            : sale
                        )
                    )
                }
            }
            return {
                ...state,
                sales: state.sales.map(
                    (sale) => (sale.descripcion
                        ? { ...sale, isChecked: true }
                        : sale
                    )
                )
            }
        },
        checkUninformedSales: (state) => {
            const salesFiltered = state.sales.filter((sale) => !sale.descripcion)
            if (salesFiltered.every((sale) => sale.isChecked)) {
                return {
                    ...state,
                    sales: state.sales.map(
                        (sale) => (!sale.descripcion
                            ? { ...sale, isChecked: false }
                            : sale
                        )
                    )
                }
            }
            return {
                ...state,
                sales: state.sales.map(
                    (sale) => (!sale.descripcion
                        ? { ...sale, isChecked: true }
                        : sale
                    )
                )
            }
        }
    },
    extraReducers: {
        [getSales.fulfilled]: (state, action) => {
            const salesFiltered = state.sales.filter((sale) => sale.codigo !== action.payload[0].barCode)
            const salesShown = action.payload[0].codigo === action.payload[0].barCode ? state.sales : salesFiltered
            if (!(state.sales.find((sale) => sale.codigo === action.payload[0].codigo))) {
                const saleDate = new Date()
                const dateMinutes = saleDate.getMinutes() <= 9 ? `0${saleDate.getMinutes()}` : saleDate.getMinutes()
                return {
                    ...state,
                    status: 'success',
                    sales: salesShown.concat(
                        {
                            codigo: action.payload[0].codigo,
                            descripcion: action.payload[0].descripcion,
                            precio: action.payload[0].precio,
                            marca: action.payload[0].marca,
                            stockGP: action.payload[0].stockGP,
                            stockMDP: action.payload[0].stockMDP,
                            stockBA: action.payload[0].stockBA,
                            isChecked: false,
                            date: saleDate.toLocaleDateString(),
                            time: `${saleDate.getHours()}:${dateMinutes}`,
                            quantity: 1
                        }
                    )
                }
            }
            return {
                ...state,
                status: 'success',
                sales: salesShown.map(
                    (sale) => (sale.codigo !== action.payload[0].codigo
                        ? sale
                        : {
                            ...sale,
                            descripcion: !sale.descripcion ? action.payload[0].descripcion : sale.descripcion,
                            precio: !sale.precio ? action.payload[0].precio : sale.precio,
                            marca: !sale.marca ? action.payload[0].marca : sale.marca,
                            stockGP: action.payload[0].stockGP,
                            stockMDP: action.payload[0].stockMDP,
                            stockBA: action.payload[0].stockBA,
                            quantity: !sale.quantity ? 1 : sale.quantity + 1
                        }
                    )
                )
            }
        },
        [getSales.rejected]: (state, action) => ({
            ...state,
            status: 'failed',
            sales: state.sales.map(
                (sale) => (sale.codigo !== action.payload.rejectedCode
                    ? sale
                    : {
                        ...sale,
                        quantity: !sale.quantity ? 1 : sale.quantity + 1
                    }
                )
            )
        })
    }
})

export const {
    changePage,
    removeSale,
    removeSales,
    removeAllSales,
    addQuantity,
    removeQuantity,
    setChecked,
    setEnteredText,
    activeFilter,
    setInitialStatus,
    checkInformedSales,
    checkUninformedSales,
    addSale
} = salesSlice.actions

export default salesSlice.reducer
