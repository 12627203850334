import { useSelector, useDispatch } from 'react-redux'
import { useEffect } from 'react'
import { changePage } from '../redux/sales'

const useActivePage = () => {
    const maxRows = 4
    const sales = useSelector((state) => state.sales.sales)
    const actualPage = useSelector((state) => state.sales.page)
    const dispatch = useDispatch()
    useEffect(() => {
        const isLastRow = !(sales.length > (actualPage * maxRows - maxRows))
        if (isLastRow) {
            const pageNumber = Math.ceil(sales.length / maxRows)
            const activePage = pageNumber > 0 ? pageNumber : 1
            dispatch(changePage(activePage))
        }
    }, [ sales ])
}

export default useActivePage
