import Header from './Layout/Header'
import Main from './Layout/Main'
import Footer from './Layout/Footer'

const App = () => (
    <>
        <Header />
        <Main />
        <Footer />
    </>
)

export default App
