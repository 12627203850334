/* eslint-disable no-unused-vars */
import { useSelector } from 'react-redux'
import { useEffect, useRef, useState } from 'react'
import useActivePage from '../../hooks/useActivePage'
import SaleRow from './SaleRow'
import SearchInput from './SearchInput'
// import DeleteButton from './DeleteButton'
// import QuantityButton from './QuantityButton'
import DeleteModal from '../../common/DeleteModal'
import SendButton from './SendButton'
// import FilterButton from './FilterButton'
import CheckButton from './CheckButton'
// import PaginateList from './PaginateList'
import PaginateProvider from '../../context/PaginateProvider'
import FilterProvider from '../../context/FilterProvider'
import InputProvider from '../../context/InputProvider'
import trashImage from '../../../assests/img/1200px-OOjs_UI_icon_trash.png'

const SalesTable = () => {
    const maxRows = 100
    const [ openModal, setOpenModal ] = useState(false)
    const closeModal = () => setOpenModal(false)
    const sales = useSelector((state) => state.sales.sales)
    const pageRendered = useSelector((state) => state.sales.page)
    const enteredText = useSelector((state) => state.sales.inputText)
    const isFilterActive = useSelector((state) => state.sales.filterText)
    // const salesFiltered = sales.filter((sale) => sale.id.startsWith(enteredText))
    const salesFiltered = sales.filter((sale) => sale.codigo.startsWith(enteredText))
    const salesRendered = isFilterActive ? salesFiltered : sales
    const saleIndexStart = (pageRendered * maxRows) - maxRows
    const saleIndexEnd = pageRendered * maxRows
    // const buttonsQuantity = Math.ceil(salesRendered.length / maxRows)
    // const buttonsRendered = buttonsQuantity >= 1 ? buttonsQuantity : 1
    const bodyRef = useRef()
    const getTotalSales = () => {
        let totalSales = 0
        sales.forEach((sale) => {
            if (sale.precio) {
                totalSales += (sale.precio * sale.quantity)
            }
        })
        return totalSales.toFixed(2)
    }
    const getSelectedRow = () => {
        const totalRows = bodyRef.current.querySelectorAll('tr')
        const rowSelected = []
        for (let i = 0; i < totalRows.length; i++) {
            if (totalRows[i].classList.contains('selected')) {
                rowSelected.push(totalRows[i])
                break
            }
        }
        return rowSelected
    }
    const cleanSelectedRow = () => {
        const saleRows = bodyRef.current.querySelectorAll('tr')
        for (let i = 0; i < saleRows.length; i++) {
            if (saleRows[i].classList.contains('selected')) {
                saleRows[i].classList.remove('selected')
                break
            }
        }
    }
    useActivePage()
    useEffect(() => {
        cleanSelectedRow()
    }, [ pageRendered ])
    return (
        <PaginateProvider>
            <InputProvider>
                {
                    openModal
                        && (
                            <DeleteModal
                                closeModal={closeModal}
                                deleteAll
                            />
                        )
                }
                <div className="table-grid">
                    <header
                        className="flex items-center bg-gray-200 lg:border-b border-b-gray-300"
                    >
                        <div className="page-container">
                            <div className="flex items-center justify-between">
                                <FilterProvider>
                                    <div className="btn-container flex gap-3 w-fit mr-4">
                                        <button
                                            type="button"
                                            className="w-[20px]"
                                            onClick={(e) => {
                                                e.stopPropagation()
                                                setOpenModal(true)
                                            }}
                                        >
                                            <img src={trashImage} alt="Trash" />
                                        </button>
                                        {/* <DeleteButton
                                            getSale={getSelectedRow}
                                            cleanSelected={cleanSelectedRow}
                                        /> */}
                                        {/*
                                        <QuantityButton
                                            getSale={getSelectedRow}
                                            cleanSelected={cleanSelectedRow}
                                        />
                                        <QuantityButton
                                            getSale={getSelectedRow}
                                            cleanSelected={cleanSelectedRow}
                                            type="subtract"
                                        />
                                        <FilterButton />
                                        */}
                                    </div>
                                    <div className="flex items-center gap-2 order-first">
                                        <div className="text-4xl font-bold text-[#999] ml-3">CliP</div>
                                        <SearchInput pageMaxRows={maxRows} />
                                        <CheckButton />
                                        <CheckButton doesExist={false} />
                                    </div>
                                </FilterProvider>
                            </div>
                        </div>
                    </header>
                    <main className="text-gray-800 font-medium overflow-auto table-size">
                        <div className="page-container py-3 lg:py-0">
                            <table className="w-full text-right lg:text-center table-fixed md:text-sm responsive-table">
                                <thead className="sticky top-0 hidden lg:table-header-group bg-gray-500 text-gray-300">
                                    <tr>
                                        <th aria-label="Checkbox" className="py-1 w-12" />
                                        <th className="py-1 px-3 text-left w-40">Código</th>
                                        <th className="py-1 w-20">Cantidad</th>
                                        <th className="py-1 text-left">Descripción</th>
                                        <th className="py-1 w-24">Estado</th>
                                        <th className="py-1 w-24">Fecha</th>
                                        <th className="py-1 px-3 text-right w-40">Precio Unitario</th>
                                        <th className="py-1 px-3 text-right w-40">Precio Total</th>
                                        <th aria-label="Button" className="py-1 w-12" />
                                    </tr>
                                </thead>
                                <tbody ref={bodyRef}>
                                    {/* {
                                        salesRendered.slice(saleIndexStart, saleIndexEnd).map(({
                                            id,
                                            stock,
                                            description,
                                            unitPrice,
                                            quantity,
                                            date,
                                            time
                                        }, index) => (
                                            <SaleRow
                                                key={index + 1}
                                                code={id}
                                                stock={stock}
                                                description={description}
                                                quantity={quantity}
                                                unitPrice={unitPrice}
                                                date={date}
                                                time={time}
                                            />
                                        ))
                                    } */}
                                    {
                                        salesRendered.slice(saleIndexStart, saleIndexEnd).map(({
                                            codigo,
                                            stockGP,
                                            stockMDP,
                                            stockBA,
                                            descripcion,
                                            precio,
                                            quantity,
                                            date,
                                            time
                                        }, index) => (
                                            <SaleRow
                                                key={index + 1}
                                                code={codigo}
                                                stock={(stockGP + stockMDP + stockBA > 0)}
                                                description={descripcion}
                                                quantity={quantity}
                                                unitPrice={precio}
                                                date={date}
                                                time={time}
                                            />
                                        ))
                                    }
                                </tbody>
                                {
                                    salesRendered.length > 0 && (
                                        <tfoot className="sticky bottom-0 text-base hidden lg:table-footer-group text-gray-200 bg-gray-500">
                                            <tr className="responsive-tfoot-cell">
                                                <td colSpan={7} className="hidden lg:table-cell text-right">Total Vendido</td>
                                                <td className="px-3 text-lg text-right">{`$${getTotalSales()}`}</td>
                                                <td>&nbsp;</td>
                                            </tr>
                                        </tfoot>
                                    )
                                }
                            </table>
                        </div>
                    </main>
                    <footer className="flex items-center bg-gray-200">
                        <div className="page-container">
                            <div className="flex justify-between items-center flex-wrap gap-3 text-gray-300">
                                <div className="px-3 hidden md:flex items-center gap-6">
                                    <SendButton />
                                    <SendButton doesExist={false} />
                                </div>
                                <span className="font-medium sm:hidden">{`$${getTotalSales()}`}</span>
                                {/* <ul className="flex items-center gap-2 w-fit text-sm">
                                    <PaginateList
                                        pageRangeDisplayed={3}
                                        pageCount={buttonsRendered}
                                    />
                                </ul> */}
                            </div>
                        </div>
                    </footer>
                </div>
            </InputProvider>
        </PaginateProvider>
    )
}

export default SalesTable
